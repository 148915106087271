exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-daniel-js": () => import("./../../../src/pages/team/daniel.js" /* webpackChunkName: "component---src-pages-team-daniel-js" */),
  "component---src-pages-team-paula-js": () => import("./../../../src/pages/team/paula.js" /* webpackChunkName: "component---src-pages-team-paula-js" */),
  "component---src-templates-card-kaldevi-template-js": () => import("./../../../src/templates/card-kaldevi-template.js" /* webpackChunkName: "component---src-templates-card-kaldevi-template-js" */),
  "component---src-templates-card-lsa-template-js": () => import("./../../../src/templates/card-lsa-template.js" /* webpackChunkName: "component---src-templates-card-lsa-template-js" */),
  "component---src-templates-card-rareastudio-template-js": () => import("./../../../src/templates/card-rareastudio-template.js" /* webpackChunkName: "component---src-templates-card-rareastudio-template-js" */),
  "component---src-templates-card-servikal-template-js": () => import("./../../../src/templates/card-servikal-template.js" /* webpackChunkName: "component---src-templates-card-servikal-template-js" */)
}

